<template>
  <div>
    <div class="detail-box1">
      <img style="height: 100%;max-width: 40vw;;" alt="Vue logo" :src="detail.url">
      <div style="display: inline-block;margin-left: 1vw;flex: 1;">
        <span class="detail-title">{{ detail.title }}</span>
        <p>
          <span style="font-size: 24px;font-weight: 500;color: #ECB332;">询价</span>
          <el-divider direction="vertical"></el-divider>
          <span style="font-size: 14px;color: #9E9E9E;">电话询价</span>
        </p>
        <div class="detail-box1-txt">
          <div class="detail-box1-tip">智能新品</div>
          <div class="detail-box1-tip">弹窗广告</div>
        </div>
        <div class="detail-box1-txt" v-if="!address">
          <div class="detail-box1-bottom">联系人：闪开新媒</div>
          <div class="detail-box1-bottom"><i class="el-icon-phone" style="margin-right: 5px;"></i>400-667-9990</div>
        </div>
        <div v-if="address" style="color: #9E9E9E;margin-top: 20px;">
          <div>入驻商：{{ address }}</div>
          <div style="margin: 5px 0;">联系人：{{ contacts }}</div>
          <div>电话：{{ tel }}</div>
        </div>
      </div>
    </div>

    <div class="detail-box1 detail-box2">
      <div class="box2-left">
        <p style="font-size: 30px;color: #2A2A2A;margin-bottom: 2%;">产品介绍</p>
        <div style="background: #F6F6F6;height: 600px;padding: 2%;">
          <div>
            <div class="comm-data-fa" v-for="(fat, index) in detail.txtList" :key="index + fat.title">
              <div class="comm-data-son-title">{{ fat.title }}</div>
              <div class="comm-data-tips" v-for="(son, index2) in fat.describe" :key="index + '-' + index2">{{ son }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box2-right">
        <p style="font-size: 18px;color: #2A2A2A;font-weight: bold;">其他推荐</p>
        <div v-for="(item, index) in otherData" :key="index" style="height: 500px;cursor: pointer;"
          @click="goToOther(item.title)">
          <img style="height: 329px;max-width: 20vw" alt="Vue logo" :src="item.url">
          <p style="font-size: 18px;font-weight: bold;color: #2A2A2A;">{{ item.title }}</p>
          <div class="detail-box1-txt">
            <div class="detail-box1-tip">智能新品</div>
            <div class="detail-box1-tip">弹窗广告</div>
          </div>
          <el-divider
            v-if="(otherData && index + 1 != otherData.length) || (otherData && otherData.length == 1)"></el-divider>
        </div>
      </div>



    </div>
  </div>
</template>
  
<script>
import { advertList, tweetList, channelList } from '../utils/content.js';

export default {
  name: 'AboutPage',
  components: {
  },
  data() {
    return {
      detail: {},
      otherData: [],
      contacts: '',
      tel: '',
      address: ''
    }
  },
  //刷新时
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
    this.getData()
  },
  watch: {
    //点返回等
    '$route'() {
      // 处理网址变化
      // console.log(`从 ${from.path} 到 ${to.path}`);
      this.getData()
    }
  },
  methods: {
    goToOther(val) {
      this.$router.push({ path: '/detail', query: { title: val } });
    },
    getData() {
      let title = this.$route.query.title
      this.contacts = title == '充电页弹窗广告' ? '吴昊' : title == '充电页banner横幅' ? '李光虹' : title == '小程序跳转广告' ? '卢明辉' : '李光虹'
      this.tel = title == '充电页弹窗广告' ? '13809045040' : title == '充电页banner横幅' ? '15736452586' : title == '小程序跳转广告' ? ' 15779012722' : '15736452586'
      this.address = title == '充电页弹窗广告' ? '南京奥赛展网络科技有限公司' : title == '充电页banner横幅' ? '重庆地心科技有限公司' : title == '小程序跳转广告' ? '赣州市帝伯乐网络科技有限公司' : '重庆地心科技有限公司'
      this.otherData = []
      if (title == '充电页弹窗广告' || title == '充电页banner横幅' || title == '充值页广告') {
        this.detail = advertList.find(item => item.title === title);
        this.otherData = advertList.filter(item => item.title !== title);
      } else if (title == '福利中心广告' || title == '充电消息广告' || title == '微信推文广告') {
        this.detail = tweetList.find(item => item.title === title);
        this.otherData = tweetList.filter(item => item.title !== title);
      } else if (title == '微信跳转广告' || title == '小程序跳转广告' || title == '支付宝跳转广告') {
        this.detail = channelList.find(item => item.title === title);
        this.otherData = channelList.filter(item => item.title !== title);
      }
    },
  }
}
</script>
  
<style scoped>
.detail-box1 {
  display: flex;
  width: 82%;
  height: 415px;
  margin: 0 auto 20px;
  box-sizing: border-box;
  /* 让 padding 包含在宽度内 */
  padding: 2%;
  border-radius: 5px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.detail-box2 {
  width: 82%;
  padding: 0;
  height: 1015px;
  background: none;
  min-height: 865px;
  box-shadow: none
}

.box2-left {
  width: 70%;
  /* height: 100%; */
  padding: 0 2% 2%;
  border-radius: 5px;
  margin-right: 2%;
  background: #FFFFFF;
}

.box2-right {
  flex: 1;
  padding: 0 2% 2%;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.detail-box1-txt {
  position: relative;
  margin: 15px 5px 0 0;
}

.detail-box1-tip {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #83B24C;
  font-size: 12px;
  text-align: center;
  color: #83B24C;
  margin-right: 5px;
  margin-bottom: 20px;
}

.detail-box1-bottom {
  display: inline-block;
  width: 184px;
  height: 43px;
  line-height: 43px;
  border-radius: 5px;
  background: #ECB332;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
}

.detail-box1-bottom:first-child {
  margin-right: 1%;
  background: #83B24C;
}

.detail-title {
  font-size: 22px;
  font-weight: 500;
  color: #2A2A2A;
}

.comm-data-title {
  font-size: 22px;
  font-weight: 500;
  color: #2A2A2A;
}

.comm-data-fa {
  margin: 25px 0;
}

.comm-data-tips {
  font-size: 14px;
  color: #9E9E9E;
  margin: 5px 0;
}

.comm-data-son-title {
  font-size: 18px;
  color: #ECB332;

}
</style>
  